.Pad {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.priority {
    width: 15%;
    color: white;
    font-family: 'Encode Sans';
    font-size: 1.5rem;
    background-color: transparent;
    border: none;
    outline: none;
    border-bottom: 1px solid white;
}
.priority::placeholder {
    color: white;
}
.priority:focus {
    border-bottom: 1px solid var(--accent);
}
.priority:focus::placeholder {
    color: var(--accent);
}
.equation {
    width: 60%;
    color: white;
    font-family: 'Encode Sans';
    font-size: 1.5rem;
    background-color: transparent;
    border: none;
    outline: none;
    border-bottom: 1px solid white;
}
.equation::placeholder {
    color: white;
}
.equation:focus {
    border-bottom: 1px solid var(--accent);
}
.equation:focus::placeholder {
    color: var(--accent);
}
.submit-button {
    background-color: transparent;
    color: white;
    border: 1px solid white;
    padding: 0;
    font-family: 'Suez One';
    font-size: 1rem;
    cursor: pointer;
    width: 15%;
    height: 2rem;
    position: relative;
    z-index: 1;
}
.submit-button:before {
    transition: 0.2s all ease;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 50%;
    opacity: 0;
    content: "";
    background-color: var(--accent);
}
.submit-button:hover:before {
    transition: 0.2s all ease;
    left: 0;
    right: 0;
    opacity: 1;
    z-index: -1;
}
