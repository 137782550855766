/* hero */
.hero-section {
    background: var(--mid-grey);
    background-image: url(../../assets/owl.png);
    background-repeat: no-repeat;
    background-position: left center;
    background-size: auto 80%;
}
.hero-container {
    padding-top: 4rem;
    padding-bottom: 5rem;
    text-align: center;
}
.hero-header {
    margin: 0;
    font-family: 'Suez One';
    font-size: 7rem;
    color: white;
}
.hero-header span {
    color: var(--accent);
}
.hero-sub {
    margin: 0;
    font-family: 'Encode Sans';
    font-size: 2rem;
    color: white;
    line-height: 3rem;
}

/* features */
.features-section {
    background-image: url(../../assets/repeating-background.png);
    background-repeat: no-repeat;
    background-position: top 1.5rem center;
    background-size: 65%;
}
.features-container {
    padding: 3rem 0;
    text-align: center;
}
.features-header {
    margin: 0;
    font-family: 'Suez One';
    font-size: 3rem;
    color: white;
}
.primary-cta {
    margin: 1.5rem 0;
    background: transparent;
    color: white;
    border: 1px solid white;
    padding: 0;
    font-family: 'Encode Sans';
    font-size: 2rem;
    cursor: pointer;
    width: 20%;
    min-height: 4rem;
    position: relative;
    z-index: 1;
}
.primary-cta:before {
    transition: 0.2s all ease;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 50%;
    opacity: 0;
    content: "";
    background-color: var(--accent);
}
.primary-cta:hover:before {
    transition: 0.2s all ease;
    left: 0;
    right: 0;
    opacity: 1;
    z-index: -1;
}
.upcoming-features-header {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    font-family: 'Suez One';
    font-size: 2rem;
    color: white;
}
.upcoming-features-content {
    display: flex;
    flex-direction: column;
}
.upcoming-features-content p{
    margin: 0;
    font-family: 'Encode Sans';
    font-size: 1.5rem;
    color: white;
    line-height: 2.5rem;
}

/* about */
.about-section {
    background-color: var(--mid-grey);
    display: flex;
    justify-content: center;
}
.about-container {
    padding: 3rem 0;
    width: 75%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.about-text-container {
    width: 55%;
    text-align: center;
}
.about-header {
    margin: 0;
    margin-bottom: 1rem;
    font-family: 'Suez One';
    font-size: 3rem;
    color: white;
}
.about-content {
    margin: 0;
    font-family: 'Encode Sans';
    font-size: 1rem;
    color: white;
    line-height: 1.75rem;
}
.profile {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.profile-picture {
    width: 100%;
}
.profile-name {
    margin: 0.5rem 0;
    color: white;
    font-family: 'Encode Sans';
    font-size: 1.25rem;
    text-align: center;
}
.profile-description {
    margin: 0;
    color: white;
    font-family: 'Encode Sans';
    font-size: 1rem;
    text-align: center;
}
