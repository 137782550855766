.Pad-mobile {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
@media screen and (orientation:portrait) {
    .priority-mobile {
        width: 20%;
        color: white;
        font-family: 'Encode Sans';
        font-size: 1rem;
        background-color: transparent;
        border: none;
        outline: none;
        border-bottom: 1px solid white;
    }
}
@media screen and (orientation:landscape) {
    .priority-mobile {
        width: 15%;
        color: white;
        font-family: 'Encode Sans';
        font-size: 1rem;
        background-color: transparent;
        border: none;
        outline: none;
        border-bottom: 1px solid white;
    }
}
.priority-mobile::placeholder {
    color: white;
}
.priority-mobile:focus {
    border-bottom: 1px solid var(--accent);
}
.priority-mobile:focus::placeholder {
    color: var(--accent);
}
@media screen and (orientation:portrait) {
    .equation-mobile {
        width: 50%;
        color: white;
        font-family: 'Encode Sans';
        font-size: 1rem;
        background-color: transparent;
        border: none;
        outline: none;
        border-bottom: 1px solid white;
    }
}
@media screen and (orientation:landscape) {
    .equation-mobile {
        width: 60%;
        color: white;
        font-family: 'Encode Sans';
        font-size: 1rem;
        background-color: transparent;
        border: none;
        outline: none;
        border-bottom: 1px solid white;
    }
}
.equation-mobile::placeholder {
    color: white;
}
.equation-mobile:focus {
    border-bottom: 1px solid var(--accent);
}
.equation-mobile:focus::placeholder {
    color: var(--accent);
}
@media screen and (orientation:portrait) {
    .submit-button-mobile {
        background-color: transparent;
        color: white;
        border: 1px solid white;
        padding: 0;
        font-family: 'Suez One';
        font-size: 0.75rem;
        width: 20%;
        min-height: 1.5rem;
        position: relative;
        z-index: 1;
    }
}
@media screen and (orientation:landscape) {
    .submit-button-mobile {
        background-color: transparent;
        color: white;
        border: 1px solid white;
        padding: 0;
        font-family: 'Suez One';
        font-size: 0.75rem;
        width: 15%;
        min-height: 1.5rem;
        position: relative;
        z-index: 1;
    }
}
.submit-button-mobile:before {
    transition: 0.2s all ease;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 50%;
    opacity: 0;
    content: "";
    background-color: var(--accent);
}
.submit-button-mobile:hover:before {
    transition: 0.2s all ease;
    left: 0;
    right: 0;
    opacity: 1;
    z-index: -1;
}
