@import url(https://fonts.googleapis.com/css2?family=Suez+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Encode+Sans:wght@300&display=swap);
:root {
    --accent: #3ca0e6;
    --error: #f44336;
    --light-grey: #202124;
    --mid-grey: #191a1c;
    --dark-grey: #101012;
}
html, #root, .App {
    height: 100%;
}
body {
    height: 100%;
    background-color: #202124;
    background-color: var(--light-grey);
}
a {
    text-decoration: none;
    color: #3ca0e6;
    color: var(--accent);
}

/* scrollbar */
::-webkit-scrollbar {
    width: 0.7rem;
}
::-webkit-scrollbar-thumb {
    background: linear-gradient(transparent, #3ca0e6 20%, #3ca0e6 80%, transparent);
    background: linear-gradient(transparent, var(--accent) 20%, var(--accent) 80%, transparent);
}
::-webkit-scrollbar-thumb:hover {
    background-color: #3ca0e6;
    background-color: var(--accent);
}

.Nav {
    background-color: var(--dark-grey);
}
.nav-container {
    margin: 0 22rem;
    height: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.home-nav {
    margin: 0;
    color: white;
    font-family: 'Suez One';
    font-size: 1.5rem;
}
.home-nav span {
    color: var(--accent);
}
.home-nav:hover {
    color: var(--accent);
}
.calculator-nav {
    margin: 0;
    color: white;
    font-family: 'Encode Sans';
    font-size: 1.25rem;
}
.calculator-nav:hover {
    color: var(--accent);
}

.nav-container-mobile {
    margin: 0 1rem;
    height: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Footer {
    background-color: var(--dark-grey);
    display: flex;
    justify-content: center;
}
.footer-container {
    width: 20%;
    height: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.legal-nav {
    font-family: 'Encode Sans';
    font-size: 0.8rem;
    margin: 0;
    color: dimgrey;
}
.legal-nav:hover {
    color: var(--accent);
}
.copyright {
    font-family: 'Encode Sans';
    font-size: 0.8rem;
    margin: 0;
    color: dimgrey;
}

.footer-container-mobile {
    margin: 0 1rem;
    width: 100%;
    height: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* hero */
.hero-section {
    background: var(--mid-grey);
    background-image: url(/static/media/owl.3bba2a12.png);
    background-repeat: no-repeat;
    background-position: left center;
    background-size: auto 80%;
}
.hero-container {
    padding-top: 4rem;
    padding-bottom: 5rem;
    text-align: center;
}
.hero-header {
    margin: 0;
    font-family: 'Suez One';
    font-size: 7rem;
    color: white;
}
.hero-header span {
    color: var(--accent);
}
.hero-sub {
    margin: 0;
    font-family: 'Encode Sans';
    font-size: 2rem;
    color: white;
    line-height: 3rem;
}

/* features */
.features-section {
    background-image: url(/static/media/repeating-background.b55d9130.png);
    background-repeat: no-repeat;
    background-position: top 1.5rem center;
    background-size: 65%;
}
.features-container {
    padding: 3rem 0;
    text-align: center;
}
.features-header {
    margin: 0;
    font-family: 'Suez One';
    font-size: 3rem;
    color: white;
}
.primary-cta {
    margin: 1.5rem 0;
    background: transparent;
    color: white;
    border: 1px solid white;
    padding: 0;
    font-family: 'Encode Sans';
    font-size: 2rem;
    cursor: pointer;
    width: 20%;
    min-height: 4rem;
    position: relative;
    z-index: 1;
}
.primary-cta:before {
    transition: 0.2s all ease;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 50%;
    opacity: 0;
    content: "";
    background-color: var(--accent);
}
.primary-cta:hover:before {
    transition: 0.2s all ease;
    left: 0;
    right: 0;
    opacity: 1;
    z-index: -1;
}
.upcoming-features-header {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    font-family: 'Suez One';
    font-size: 2rem;
    color: white;
}
.upcoming-features-content {
    display: flex;
    flex-direction: column;
}
.upcoming-features-content p{
    margin: 0;
    font-family: 'Encode Sans';
    font-size: 1.5rem;
    color: white;
    line-height: 2.5rem;
}

/* about */
.about-section {
    background-color: var(--mid-grey);
    display: flex;
    justify-content: center;
}
.about-container {
    padding: 3rem 0;
    width: 75%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.about-text-container {
    width: 55%;
    text-align: center;
}
.about-header {
    margin: 0;
    margin-bottom: 1rem;
    font-family: 'Suez One';
    font-size: 3rem;
    color: white;
}
.about-content {
    margin: 0;
    font-family: 'Encode Sans';
    font-size: 1rem;
    color: white;
    line-height: 1.75rem;
}
.profile {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.profile-picture {
    width: 100%;
}
.profile-name {
    margin: 0.5rem 0;
    color: white;
    font-family: 'Encode Sans';
    font-size: 1.25rem;
    text-align: center;
}
.profile-description {
    margin: 0;
    color: white;
    font-family: 'Encode Sans';
    font-size: 1rem;
    text-align: center;
}

/* hero */
@media screen and (orientation:portrait) {
    .hero-section-mobile {
        background: var(--mid-grey);
        background-image: url(/static/media/owl.3bba2a12.png);
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 90% auto;
    }
}
@media screen and (orientation:landscape) {
    .hero-section-mobile {
        background: var(--mid-grey);
        background-image: url(/static/media/owl.3bba2a12.png);
        background-repeat: no-repeat;
        background-position: left center;
        background-size: auto 85%;
    }
}
.hero-container-mobile {
    margin: 0 1rem;
    padding: 3rem 0;
    text-align: center;
}
.hero-header-mobile {
    margin: 0;
    font-family: 'Suez One';
    font-size: 3rem;
    color: white;
}
.hero-header-mobile span {
    color: var(--accent);
}
.hero-sub-mobile {
    margin: 0;
    font-family: 'Encode Sans';
    font-size: 1.25rem;
    color: white;
    line-height: 1.75rem;
}

/* features */
.features-section-mobile {
    background-image: url(/static/media/repeating-background.b55d9130.png);
    background-repeat: no-repeat;
    background-position: top 1.25rem center;
    background-size: 90% auto;
}
.features-container-mobile {
    margin: 0 1rem;
    padding: 2rem 0;
    text-align: center;
}
.features-header-mobile {
    margin: 0;
    font-family: 'Suez One';
    font-size: 1.5rem;
    color: white;
}
@media screen and (orientation:portrait) {
    .primary-cta-mobile {
        margin: 1rem 0;
        background: transparent;
        color: white;
        border: 1px solid white;
        padding: 0;
        font-family: 'Encode Sans';
        font-size: 1.25rem;
        width: 60%;
        min-height: 3rem;
        position: relative;
        z-index: 1;
    }
}
@media screen and (orientation:landscape) {
    .primary-cta-mobile {
        margin: 1rem 0;
        background: transparent;
        color: white;
        border: 1px solid white;
        padding: 0;
        font-family: 'Encode Sans';
        font-size: 1.25rem;
        width: 40%;
        min-height: 3rem;
        position: relative;
        z-index: 1;
    }
}
.primary-cta-mobile:before {
    transition: 0.2s all ease;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 50%;
    opacity: 0;
    content: "";
    background-color: var(--accent);
}
.primary-cta-mobile:hover:before {
    transition: 0.2s all ease;
    left: 0;
    right: 0;
    opacity: 1;
    z-index: -1;
}
.upcoming-features-header-mobile {
    margin: 0.5rem 0;
    font-family: 'Suez One';
    font-size: 1.25rem;
    color: white;
}
.upcoming-features-content-mobile {
    display: flex;
    flex-direction: column;
}
.upcoming-features-content-mobile p{
    margin: 0;
    font-family: 'Encode Sans';
    font-size: 1rem;
    color: white;
    line-height: 1.5rem;
}

/* about */
.about-section-mobile {
    background-color: var(--mid-grey);
}
@media screen and (orientation:portrait) {
    .about-container-mobile {
        margin: 0 1rem;
        padding: 2rem 0;
        display: flex;
        flex-direction: column;
    }
}
@media screen and (orientation:landscape) {
    .about-container-mobile {
        margin: 0 3rem;
        padding: 2rem 0;
        display: flex;
        flex-direction: column;
    }
}
.about-text-container-mobile {
    text-align: center;
}
.about-header-mobile {
    margin: 0;
    margin-bottom: 0.5rem;
    font-family: 'Suez One';
    font-size: 1.5rem;
    color: white;
}
.about-content-mobile {
    margin: 0;
    font-family: 'Encode Sans';
    font-size: 0.75rem;
    color: white;
    line-height: 1.25rem;
}
@media screen and (orientation:portrait) {
    .profile-container-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .profile-mobile {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
@media screen and (orientation:landscape) {
    .profile-container-mobile {
        display: flex;
        justify-content: space-around;
    }
    .profile-mobile {
        width: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
.profile-picture-mobile {
    width: 100%;
}
.profile-name-mobile {
    margin: 0.5rem 0;
    color: white;
    font-family: 'Encode Sans';
    font-size: 1.25rem;
    text-align: center;
}
.profile-description-mobile {
    margin: 0;
    color: white;
    font-family: 'Encode Sans';
    font-size: 1rem;
    text-align: center;
}

.Calculator {
    height: calc(100% - 5rem);
    position: relative;
}
.calculator-container {
    height: 100%;
    background-image: url(/static/media/repeating-background.b55d9130.png);
    background-repeat: no-repeat;
    background-position: top 5rem center;
    background-size: 65%;
}

/* pad section */
.pad-section {
    background-color: var(--mid-grey);
}
.pad-container {
    height: 4rem;
    margin: 0 22rem;
    display: flex;
    align-items: center;
}

/* main section */
.main-section {
    height: calc(100% - 12rem);
    margin: 0 22rem;
    padding: 2rem 0;
    display: flex;
    justify-content: space-between;
}
.main-heading {
    margin: 0;
    padding-bottom: 1rem;
    color: white;
    font-family: 'Suez One';
    font-size: 2rem;
    text-align: center;
}
.main-heading-blank {
    margin: 0;
    padding-bottom: 1rem;
    color: dimgrey;
    font-family: 'Suez One';
    font-size: 2rem;
    text-align: center;
}
.equation-container {
    width: 69%;
}
.equation-table {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100% - 4rem);
    display: flex;
}
.variable-container {
    width: 29%;
}
.variable-table {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100% - 4rem);
    display: flex;
}

/* control section */
.control-section {
    background-color: var(--mid-grey);
}
.control-container {
    height: 4rem;
    margin: 0 22rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.buttons-container {
    width: 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.bug-button, .help-button {
    background-color: transparent;
    color: white;
    border: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 50%;
}
.bug-button:hover {
    color: var(--error);
}
.help-button:hover {
    color: var(--accent);
}
.message {
    margin: 0;
    color: white;
    font-family: 'Encode Sans';
    font-size: 0.75rem;
    font-style: italic;
}
.solve-button {
    background-color: transparent;
    color: white;
    border: 1px solid white;
    padding: 0;
    font-family: 'Suez One';
    font-size: 1rem;
    cursor: pointer;
    width: 15%;
    height: 2rem;
    position: relative;
    z-index: 1;
}
.solve-button:before {
    transition: 0.2s all ease;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 50%;
    opacity: 0;
    content: "";
    background-color: var(--accent);
}
.solve-button:hover:before {
    transition: 0.2s all ease;
    left: 0;
    right: 0;
    opacity: 1;
    z-index: -1;
}

/* ribbons */
.ribbon {
    width: 100%;
    height: 1px;
    background-color: var(--error);
    position: absolute;
    bottom: 0;
    left: 0;
}

/* ads */
.aaa {
    color: white;
    background-color: var(--mid-grey);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 600px;
    position: absolute;
    left: 1rem;
    top: 8rem;
}
.bbb {
    color: white;
    background-color: var(--mid-grey);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 250px;
    position: absolute;
    right: 1rem;
    top: 8rem;
}

/* tooltips for Equation.css and Variable.css */
.button-text {
    background-color: var(--dark-grey);
    color: white;
    font-family: 'Encode Sans';
    font-size: 1rem;
    text-align: center;
    padding: 0.3rem 0.5rem;
    border-radius: 0.2rem;
    position: absolute;
    top: 130%;
    visibility: hidden;
    z-index: 1;
}
.button-text::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -0.3rem;
    border-width: 0.3rem;
    border-style: solid;
    border-color: transparent transparent var(--dark-grey) transparent;
    z-index: 1;
}
.edit-button:hover + .button-text, .delete-button:hover + .button-text, .select-button:hover + .button-text {
    visibility: visible;
}

.Calculator-mobile {
    height: 1px;
    min-height: calc(100% - 5rem);
    overflow: auto;
}
.calculator-container-mobile {
    height: 1px;
    min-height: 100%;
    background-image: url(/static/media/repeating-background.b55d9130.png);
    background-repeat: no-repeat;
    background-position: top 3.5rem center;
    background-size: 98%;
}

/* pad section */
.pad-section-mobile {
    background-color: var(--mid-grey);
}
.pad-container-mobile {
    min-height: 3rem;
    margin: 0 1rem;
    display: flex;
    align-items: center;
}

/* main section */
@media screen and (orientation:portrait) {
    .main-section-mobile {
        min-height: calc(100% - 6.5rem);
        margin: 0 1rem;
        padding-bottom: 0.5rem;
    }
}
@media screen and (orientation:landscape) {
    .main-section-mobile {
        min-height: calc(100% - 6.5rem);
        margin: 0 1rem;
        padding-bottom: 0.5rem;
        display: flex;
        justify-content: space-between;
    }
}
.main-heading-mobile {
    margin: 0;
    padding: 1rem 0 0.5rem 0;
    color: white;
    font-family: 'Suez One';
    font-size: 1.25rem;
    text-align: center;
}
.main-heading-mobile-blank {
    margin: 0;
    padding: 1rem 0 0.5rem 0;
    color: dimgrey;
    font-family: 'Suez One';
    font-size: 1.25rem;
    text-align: center;
}
@media screen and (orientation:portrait) {
    .equation-container-mobile {
        width: 100%;
    }
    .variable-container-mobile {
        width: 100%;
    }
}
@media screen and (orientation:landscape) {
    .equation-container-mobile {
        width: 69%;
    }
    .variable-container-mobile {
        width: 29%;
    }
}

/* control section */
.control-section-mobile {
    background-color: var(--mid-grey);
}
.control-container-mobile {
    min-height: 3rem;
    margin: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media screen and (orientation:portrait) {
    .buttons-container-mobile {
        width: 20%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
@media screen and (orientation:landscape) {
    .buttons-container-mobile {
        width: 15%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
.bug-button-mobile, .help-button-mobile {
    background-color: transparent;
    color: white;
    border: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
}
.bug-button-mobile:hover {
    color: var(--error);
}
.help-button-mobile:hover {
    color: var(--accent);
}
.message-mobile {
    margin: 0;
    color: white;
    font-family: 'Encode Sans';
    font-size: 0.5rem;
    font-style: italic;
}
@media screen and (orientation:portrait) {
    .solve-button-mobile {
        background-color: transparent;
        color: white;
        border: 1px solid white;
        padding: 0;
        font-family: 'Suez One';
        font-size: 0.75rem;
        width: 20%;
        min-height: 1.5rem;
        position: relative;
        z-index: 1;
    }
}
@media screen and (orientation:landscape) {
    .solve-button-mobile {
        background-color: transparent;
        color: white;
        border: 1px solid white;
        padding: 0;
        font-family: 'Suez One';
        font-size: 0.75rem;
        width: 15%;
        min-height: 1.5rem;
        position: relative;
        z-index: 1;
    }
}
.solve-button-mobile:before {
    transition: 0.2s all ease;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 50%;
    opacity: 0;
    content: "";
    background-color: var(--accent);
}
.solve-button-mobile:hover:before {
    transition: 0.2s all ease;
    left: 0;
    right: 0;
    opacity: 1;
    z-index: -1;
}

.Pad {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.priority {
    width: 15%;
    color: white;
    font-family: 'Encode Sans';
    font-size: 1.5rem;
    background-color: transparent;
    border: none;
    outline: none;
    border-bottom: 1px solid white;
}
.priority::-webkit-input-placeholder {
    color: white;
}
.priority:-ms-input-placeholder {
    color: white;
}
.priority::placeholder {
    color: white;
}
.priority:focus {
    border-bottom: 1px solid var(--accent);
}
.priority:focus::-webkit-input-placeholder {
    color: var(--accent);
}
.priority:focus:-ms-input-placeholder {
    color: var(--accent);
}
.priority:focus::placeholder {
    color: var(--accent);
}
.equation {
    width: 60%;
    color: white;
    font-family: 'Encode Sans';
    font-size: 1.5rem;
    background-color: transparent;
    border: none;
    outline: none;
    border-bottom: 1px solid white;
}
.equation::-webkit-input-placeholder {
    color: white;
}
.equation:-ms-input-placeholder {
    color: white;
}
.equation::placeholder {
    color: white;
}
.equation:focus {
    border-bottom: 1px solid var(--accent);
}
.equation:focus::-webkit-input-placeholder {
    color: var(--accent);
}
.equation:focus:-ms-input-placeholder {
    color: var(--accent);
}
.equation:focus::placeholder {
    color: var(--accent);
}
.submit-button {
    background-color: transparent;
    color: white;
    border: 1px solid white;
    padding: 0;
    font-family: 'Suez One';
    font-size: 1rem;
    cursor: pointer;
    width: 15%;
    height: 2rem;
    position: relative;
    z-index: 1;
}
.submit-button:before {
    transition: 0.2s all ease;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 50%;
    opacity: 0;
    content: "";
    background-color: var(--accent);
}
.submit-button:hover:before {
    transition: 0.2s all ease;
    left: 0;
    right: 0;
    opacity: 1;
    z-index: -1;
}

.Pad-mobile {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
@media screen and (orientation:portrait) {
    .priority-mobile {
        width: 20%;
        color: white;
        font-family: 'Encode Sans';
        font-size: 1rem;
        background-color: transparent;
        border: none;
        outline: none;
        border-bottom: 1px solid white;
    }
}
@media screen and (orientation:landscape) {
    .priority-mobile {
        width: 15%;
        color: white;
        font-family: 'Encode Sans';
        font-size: 1rem;
        background-color: transparent;
        border: none;
        outline: none;
        border-bottom: 1px solid white;
    }
}
.priority-mobile::-webkit-input-placeholder {
    color: white;
}
.priority-mobile:-ms-input-placeholder {
    color: white;
}
.priority-mobile::placeholder {
    color: white;
}
.priority-mobile:focus {
    border-bottom: 1px solid var(--accent);
}
.priority-mobile:focus::-webkit-input-placeholder {
    color: var(--accent);
}
.priority-mobile:focus:-ms-input-placeholder {
    color: var(--accent);
}
.priority-mobile:focus::placeholder {
    color: var(--accent);
}
@media screen and (orientation:portrait) {
    .equation-mobile {
        width: 50%;
        color: white;
        font-family: 'Encode Sans';
        font-size: 1rem;
        background-color: transparent;
        border: none;
        outline: none;
        border-bottom: 1px solid white;
    }
}
@media screen and (orientation:landscape) {
    .equation-mobile {
        width: 60%;
        color: white;
        font-family: 'Encode Sans';
        font-size: 1rem;
        background-color: transparent;
        border: none;
        outline: none;
        border-bottom: 1px solid white;
    }
}
.equation-mobile::-webkit-input-placeholder {
    color: white;
}
.equation-mobile:-ms-input-placeholder {
    color: white;
}
.equation-mobile::placeholder {
    color: white;
}
.equation-mobile:focus {
    border-bottom: 1px solid var(--accent);
}
.equation-mobile:focus::-webkit-input-placeholder {
    color: var(--accent);
}
.equation-mobile:focus:-ms-input-placeholder {
    color: var(--accent);
}
.equation-mobile:focus::placeholder {
    color: var(--accent);
}
@media screen and (orientation:portrait) {
    .submit-button-mobile {
        background-color: transparent;
        color: white;
        border: 1px solid white;
        padding: 0;
        font-family: 'Suez One';
        font-size: 0.75rem;
        width: 20%;
        min-height: 1.5rem;
        position: relative;
        z-index: 1;
    }
}
@media screen and (orientation:landscape) {
    .submit-button-mobile {
        background-color: transparent;
        color: white;
        border: 1px solid white;
        padding: 0;
        font-family: 'Suez One';
        font-size: 0.75rem;
        width: 15%;
        min-height: 1.5rem;
        position: relative;
        z-index: 1;
    }
}
.submit-button-mobile:before {
    transition: 0.2s all ease;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 50%;
    opacity: 0;
    content: "";
    background-color: var(--accent);
}
.submit-button-mobile:hover:before {
    transition: 0.2s all ease;
    left: 0;
    right: 0;
    opacity: 1;
    z-index: -1;
}

.Equations {
    width: 100%;
}

.Equation {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    padding: 1.2rem;
    min-height: 2.2rem;
    color: white;
    background-color: var(--mid-grey);
    position: relative;
}
.equation-text {
    margin: 0;
    font-family: 'Encode Sans';
    font-size: 1.5rem;
    word-wrap: break-word;
}
.equation-grid {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.edit-button, .delete-button {
    background-color: transparent;
    color: white;
    border: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    cursor: pointer;
    z-index: 2;
}
.edit-button:hover {
    color: var(--accent);
}
.delete-button:hover {
    color: var(--error);
}

/* tooltips in Calculator.css */

.Equation-mobile {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    padding: 0.75rem;
    min-height: 1.5rem;
    color: white;
    background-color: var(--mid-grey);
    position: relative;
}
.equation-text-mobile {
    margin: 0;
    font-family: 'Encode Sans';
    font-size: 1rem;
    word-wrap: break-word;
}
.equation-grid-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.edit-button-mobile, .delete-button-mobile {
    background-color: transparent;
    color: white;
    border: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    z-index: 2;
}
.edit-button-mobile:hover {
    color: var(--accent);
}
.delete-button-mobile:hover {
    color: var(--error);
}

.equation-blank-item {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    padding: 1.2rem;
    height: 2.2rem;
    color: dimgrey;
    background: repeating-linear-gradient(135deg, var(--mid-grey), var(--mid-grey) 15px, transparent 15px, transparent 30px);
}
.equation-blank-text {
    margin: 0;
    font-family: 'Encode Sans';
    font-size: 1.5rem;
    font-style: italic;
}
.equation-blank-grid {
    display: flex;
    justify-content: center;
    align-items: center;
}
.blank-edit-button, .blank-delete-button {
    background-color: transparent;
    color: dimgrey;
    border: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.equation-blank-item-mobile {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    padding: 0.75rem;
    min-height: 1.5rem;
    color: dimgrey;
    background: repeating-linear-gradient(135deg, var(--mid-grey), var(--mid-grey) 15px, transparent 15px, transparent 30px);
}
.equation-blank-text-mobile {
    margin: 0;
    font-family: 'Encode Sans';
    font-size: 1rem;
    font-style: italic;
}
.equation-blank-grid-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
}
.blank-edit-button-mobile, .blank-delete-button-mobile {
    background-color: transparent;
    color: dimgrey;
    border: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Variables {
    width: 100%;
}

.Variable {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    padding: 1.2rem;
    min-height: 2.2rem;
    color: white;
    background-color: var(--mid-grey);
    position: relative;
}
.variable-text {
    margin: 0;
    font-family: 'Encode Sans';
    font-size: 1.5rem;
}
.variable-input {
    width: 98%;
    color: white;
    font-family: 'Encode Sans';
    font-size: 1.5rem;
    background-color: transparent;
    border: none;
    outline: none;
    border-bottom: 1px solid white;
}
.variable-input::-webkit-input-placeholder {
    color: white;
}
.variable-input:-ms-input-placeholder {
    color: white;
}
.variable-input::placeholder {
    color: white;
}
.variable-input:focus {
    border-bottom: 1px solid var(--accent);
}
.variable-input:focus::-webkit-input-placeholder {
    color: var(--accent);
}
.variable-input:focus:-ms-input-placeholder {
    color: var(--accent);
}
.variable-input:focus::placeholder {
    color: var(--accent);
}
.variable-value {
    width: 100%;
    font-family: 'Encode Sans';
    font-size: 1.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    /* white-space: nowrap; */
}
.variable-grid {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.select-button {
    background-color: transparent;
    color: white;
    border: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    cursor: pointer;
    z-index: 2;
}
.select-button:hover {
    color: var(--accent);
}

/* tooltips in Calculator.css */

.Variable-mobile {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    padding: 0.75rem;
    min-height: 1.5rem;
    color: white;
    background-color: var(--mid-grey);
    position: relative;
}
.variable-text-mobile {
    margin: 0;
    font-family: 'Encode Sans';
    font-size: 1rem;
}
.variable-input-mobile {
    width: 96%;
    color: white;
    font-family: 'Encode Sans';
    font-size: 1rem;
    background-color: transparent;
    border: none;
    outline: none;
    border-bottom: 1px solid white;
}
.variable-input-mobile::-webkit-input-placeholder {
    color: white;
}
.variable-input-mobile:-ms-input-placeholder {
    color: white;
}
.variable-input-mobile::placeholder {
    color: white;
}
.variable-input-mobile:focus {
    border-bottom: 1px solid var(--accent);
}
.variable-input-mobile:focus::-webkit-input-placeholder {
    color: var(--accent);
}
.variable-input-mobile:focus:-ms-input-placeholder {
    color: var(--accent);
}
.variable-input-mobile:focus::placeholder {
    color: var(--accent);
}
.variable-value-mobile {
    width: 100%;
    font-family: 'Encode Sans';
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
}
.variable-grid-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.select-button-mobile {
    background-color: transparent;
    color: white;
    border: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    z-index: 2;
}
.select-button-mobile:hover {
    color: var(--accent);
}

.variable-blank-item {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    padding: 1.2rem;
    height: 2.2rem;
    color: dimgrey;
    background: repeating-linear-gradient(135deg, var(--mid-grey), var(--mid-grey) 15px, transparent 15px, transparent 30px);
}
.variable-blank-text {
    margin: 0;
    font-family: 'Encode Sans';
    font-size: 1.5rem;
    font-style: italic;
}
.variable-blank-grid {
    display: flex;
    justify-content: center;
    align-items: center;
}
.blank-select-button {
    background-color: transparent;
    color: dimgrey;
    border: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.variable-blank-unselected {
    border-bottom: 1px solid dimgrey;
}

.variable-blank-item-mobile {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    padding: 0.75rem;
    min-height: 1.5rem;
    color: dimgrey;
    background: repeating-linear-gradient(135deg, var(--mid-grey), var(--mid-grey) 15px, transparent 15px, transparent 30px);
}
.variable-blank-text-mobile {
    margin: 0;
    font-family: 'Encode Sans';
    font-size: 1rem;
    font-style: italic;
}
.variable-blank-grid-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
}
.blank-select-button-mobile {
    background-color: transparent;
    color: dimgrey;
    border: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.variable-blank-unselected-mobile {
    border-bottom: 1px solid dimgrey;
}

.BugDialog {
    width: 54rem;
    background-color: var(--light-grey);
    padding: 3rem;
    display: flex;
    flex-direction: column;
}
.form-title {
    margin: 0;
    color: white;
    font-family: 'Suez One';
    font-size: 2rem;
}
.form-text {
    color: white;
    font-family: 'Encode Sans';
    font-size: 1.5rem;
    margin-top: 1.5rem;
    background-color: transparent;
    border: none;
    outline: none;
    border-bottom: 1px solid white;
}
.form-text::-webkit-input-placeholder {
    color: white;
}
.form-text:-ms-input-placeholder {
    color: white;
}
.form-text::placeholder {
    color: white;
}
.form-text:focus {
    border-bottom: 1px solid var(--accent);
}
.form-text:focus::-webkit-input-placeholder {
    color: var(--accent);
}
.form-text:focus:-ms-input-placeholder {
    color: var(--accent);
}
.form-text:focus::placeholder {
    color: var(--accent);
}
.form-textarea {
    resize: none;
    color: white;
    font-family: 'Encode Sans';
    font-size: 1.5rem;
    margin: 2rem 0;
    height: 10rem;
    background-color: transparent;
    border: none;
    outline: none;
    border: 1px solid white;
}
.form-textarea::-webkit-input-placeholder {
    color: white;
}
.form-textarea:-ms-input-placeholder {
    color: white;
}
.form-textarea::placeholder {
    color: white;
}
.form-textarea:focus {
    border: 1px solid var(--accent);
}
.form-textarea:focus::-webkit-input-placeholder {
    color: var(--accent);
}
.form-textarea:focus:-ms-input-placeholder {
    color: var(--accent);
}
.form-textarea:focus::placeholder {
    color: var(--accent);
}
.form-button {
    display: flex;
    flex-direction: row-reverse;
}
.form-submit {
    background-color: transparent;
    color: white;
    border: 1px solid white;
    padding: 0;
    font-family: 'Suez One';
    font-size: 1rem;
    cursor: pointer;
    width: 15%;
    height: 2rem;
    position: relative;
    z-index: 1;
}
.form-submit:before {
    transition: 0.2s all ease;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 50%;
    opacity: 0;
    content: "";
    background-color: var(--accent);
}
.form-submit:hover:before {
    transition: 0.2s all ease;
    left: 0;
    right: 0;
    opacity: 1;
    z-index: -1;
}

.BugDialog-mobile {
    width: 30rem;
    background-color: var(--light-grey);
    padding: 1rem;
    display: flex;
    flex-direction: column;
}
.form-title-mobile {
    margin: 0;
    color: white;
    font-family: 'Suez One';
    font-size: 1.25rem;
}
.form-text-mobile {
    color: white;
    font-family: 'Encode Sans';
    font-size: 1rem;
    margin-top: 1rem;
    background-color: transparent;
    border: none;
    outline: none;
    border-bottom: 1px solid white;
}
.form-text-mobile::-webkit-input-placeholder {
    color: white;
}
.form-text-mobile:-ms-input-placeholder {
    color: white;
}
.form-text-mobile::placeholder {
    color: white;
}
.form-text-mobile:focus {
    border-bottom: 1px solid var(--accent);
}
.form-text-mobile:focus::-webkit-input-placeholder {
    color: var(--accent);
}
.form-text-mobile:focus:-ms-input-placeholder {
    color: var(--accent);
}
.form-text-mobile:focus::placeholder {
    color: var(--accent);
}
.form-textarea-mobile {
    resize: none;
    color: white;
    font-family: 'Encode Sans';
    font-size: 1rem;
    margin: 1.5rem 0;
    height: 5rem;
    background-color: transparent;
    border: none;
    outline: none;
    border: 1px solid white;
}
.form-textarea-mobile::-webkit-input-placeholder {
    color: white;
}
.form-textarea-mobile:-ms-input-placeholder {
    color: white;
}
.form-textarea-mobile::placeholder {
    color: white;
}
.form-textarea-mobile:focus {
    border: 1px solid var(--accent);
}
.form-textarea-mobile:focus::-webkit-input-placeholder {
    color: var(--accent);
}
.form-textarea-mobile:focus:-ms-input-placeholder {
    color: var(--accent);
}
.form-textarea-mobile:focus::placeholder {
    color: var(--accent);
}
.form-button-mobile {
    display: flex;
    flex-direction: row-reverse;
}
.form-submit-mobile {
    background-color: transparent;
    color: white;
    border: 1px solid white;
    padding: 0;
    font-family: 'Suez One';
    font-size: 0.75rem;
    width: 15%;
    height: 1.5rem;
    position: relative;
    z-index: 1;
}
.form-submit-mobile:before {
    transition: 0.2s all ease;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 50%;
    opacity: 0;
    content: "";
    background-color: var(--accent);
}
.form-submit-mobile:hover:before {
    transition: 0.2s all ease;
    left: 0;
    right: 0;
    opacity: 1;
    z-index: -1;
}

.HelpDialog {
    background-color: var(--mid-grey);
    width: 22rem;
    height: 10rem;
    padding: 1rem;
    position: absolute;
    bottom: 5rem;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    display: flex;
    flex-direction: column;
    z-index: 3;
}
.help-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.help-controls-title {
    margin: 0;
    font-family: 'Suez One';
    font-size: 1.5rem;
    color: white;
}
.help-controls-text {
    margin: 0;
    font-family: 'Encode Sans';
    font-size: 1rem;
    color: white;
}
.help-controls-button {
    background-color: transparent;
    color: white;
    border: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 15%;
}
.help-controls-button:hover {
    color: var(--accent);
}
.help-text {
    margin: 0.5rem 0;
    font-family: 'Encode Sans';
    font-size: 1.25rem;
    color: white;
    flex-grow: 1;
}

.HelpDialog-mobile {
    background-color: var(--mid-grey);
    width: 14rem;
    height: 7rem;
    padding: 1rem;
    position: fixed;
    bottom: 1rem;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    display: flex;
    flex-direction: column;
    z-index: 3;
}
.help-controls-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.help-controls-title-mobile {
    margin: 0;
    font-family: 'Suez One';
    font-size: 1rem;
    color: white;
}
.help-controls-text-mobile {
    margin: 0;
    font-family: 'Encode Sans';
    font-size: 0.75rem;
    color: white;
}
.help-controls-button-mobile {
    background-color: transparent;
    color: white;
    border: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;
}
.help-controls-button-mobile:hover {
    color: var(--accent);
}
.help-text-mobile {
    margin: 0.5rem 0;
    font-family: 'Encode Sans';
    font-size: 0.75rem;
    color: white;
    flex-grow: 1;
}

.Terms {
    background-image: url(/static/media/repeating-background.b55d9130.png);
    background-repeat: no-repeat;
    background-position: top 1rem center;
    background-size: 65%;
}
.terms-container {
    margin: 0 22rem;
    padding: 0.25rem 0 1.25rem 0;
    color: white;
}

.Terms-mobile {
    background-image: url(/static/media/repeating-background.b55d9130.png);
    background-repeat: no-repeat;
    background-position: top 1.25rem center;
    background-size: 90% auto;
}
.terms-container-mobile {
    margin: 0 1rem;
    padding: 0.25rem 0 1.25rem 0;
    color: white;
}

.Privacy {
    background-image: url(/static/media/repeating-background.b55d9130.png);
    background-repeat: no-repeat;
    background-position: top 1rem center;
    background-size: 65%;
}
.privacy-container {
    margin: 0 22rem;
    padding: 0.25rem 0 1.25rem 0;
    color: white;
}

.Privacy-mobile {
    background-image: url(/static/media/repeating-background.b55d9130.png);
    background-repeat: no-repeat;
    background-position: top 1.25rem center;
    background-size: 90% auto;
}
.privacy-container-mobile {
    margin: 0 1rem;
    padding: 0.25rem 0 1.25rem 0;
    color: white;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

