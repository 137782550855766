.HelpDialog-mobile {
    background-color: var(--mid-grey);
    width: 14rem;
    height: 7rem;
    padding: 1rem;
    position: fixed;
    bottom: 1rem;
    left: 50%;
    transform: translate(-50%);
    display: flex;
    flex-direction: column;
    z-index: 3;
}
.help-controls-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.help-controls-title-mobile {
    margin: 0;
    font-family: 'Suez One';
    font-size: 1rem;
    color: white;
}
.help-controls-text-mobile {
    margin: 0;
    font-family: 'Encode Sans';
    font-size: 0.75rem;
    color: white;
}
.help-controls-button-mobile {
    background-color: transparent;
    color: white;
    border: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;
}
.help-controls-button-mobile:hover {
    color: var(--accent);
}
.help-text-mobile {
    margin: 0.5rem 0;
    font-family: 'Encode Sans';
    font-size: 0.75rem;
    color: white;
    flex-grow: 1;
}
