.BugDialog-mobile {
    width: 30rem;
    background-color: var(--light-grey);
    padding: 1rem;
    display: flex;
    flex-direction: column;
}
.form-title-mobile {
    margin: 0;
    color: white;
    font-family: 'Suez One';
    font-size: 1.25rem;
}
.form-text-mobile {
    color: white;
    font-family: 'Encode Sans';
    font-size: 1rem;
    margin-top: 1rem;
    background-color: transparent;
    border: none;
    outline: none;
    border-bottom: 1px solid white;
}
.form-text-mobile::placeholder {
    color: white;
}
.form-text-mobile:focus {
    border-bottom: 1px solid var(--accent);
}
.form-text-mobile:focus::placeholder {
    color: var(--accent);
}
.form-textarea-mobile {
    resize: none;
    color: white;
    font-family: 'Encode Sans';
    font-size: 1rem;
    margin: 1.5rem 0;
    height: 5rem;
    background-color: transparent;
    border: none;
    outline: none;
    border: 1px solid white;
}
.form-textarea-mobile::placeholder {
    color: white;
}
.form-textarea-mobile:focus {
    border: 1px solid var(--accent);
}
.form-textarea-mobile:focus::placeholder {
    color: var(--accent);
}
.form-button-mobile {
    display: flex;
    flex-direction: row-reverse;
}
.form-submit-mobile {
    background-color: transparent;
    color: white;
    border: 1px solid white;
    padding: 0;
    font-family: 'Suez One';
    font-size: 0.75rem;
    width: 15%;
    height: 1.5rem;
    position: relative;
    z-index: 1;
}
.form-submit-mobile:before {
    transition: 0.2s all ease;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 50%;
    opacity: 0;
    content: "";
    background-color: var(--accent);
}
.form-submit-mobile:hover:before {
    transition: 0.2s all ease;
    left: 0;
    right: 0;
    opacity: 1;
    z-index: -1;
}
