.Calculator {
    height: calc(100% - 5rem);
    position: relative;
}
.calculator-container {
    height: 100%;
    background-image: url(../../assets/repeating-background.png);
    background-repeat: no-repeat;
    background-position: top 5rem center;
    background-size: 65%;
}

/* pad section */
.pad-section {
    background-color: var(--mid-grey);
}
.pad-container {
    height: 4rem;
    margin: 0 22rem;
    display: flex;
    align-items: center;
}

/* main section */
.main-section {
    height: calc(100% - 12rem);
    margin: 0 22rem;
    padding: 2rem 0;
    display: flex;
    justify-content: space-between;
}
.main-heading {
    margin: 0;
    padding-bottom: 1rem;
    color: white;
    font-family: 'Suez One';
    font-size: 2rem;
    text-align: center;
}
.main-heading-blank {
    margin: 0;
    padding-bottom: 1rem;
    color: dimgrey;
    font-family: 'Suez One';
    font-size: 2rem;
    text-align: center;
}
.equation-container {
    width: 69%;
}
.equation-table {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100% - 4rem);
    display: flex;
}
.variable-container {
    width: 29%;
}
.variable-table {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100% - 4rem);
    display: flex;
}

/* control section */
.control-section {
    background-color: var(--mid-grey);
}
.control-container {
    height: 4rem;
    margin: 0 22rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.buttons-container {
    width: 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.bug-button, .help-button {
    background-color: transparent;
    color: white;
    border: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 50%;
}
.bug-button:hover {
    color: var(--error);
}
.help-button:hover {
    color: var(--accent);
}
.message {
    margin: 0;
    color: white;
    font-family: 'Encode Sans';
    font-size: 0.75rem;
    font-style: italic;
}
.solve-button {
    background-color: transparent;
    color: white;
    border: 1px solid white;
    padding: 0;
    font-family: 'Suez One';
    font-size: 1rem;
    cursor: pointer;
    width: 15%;
    height: 2rem;
    position: relative;
    z-index: 1;
}
.solve-button:before {
    transition: 0.2s all ease;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 50%;
    opacity: 0;
    content: "";
    background-color: var(--accent);
}
.solve-button:hover:before {
    transition: 0.2s all ease;
    left: 0;
    right: 0;
    opacity: 1;
    z-index: -1;
}

/* ribbons */
.ribbon {
    width: 100%;
    height: 1px;
    background-color: var(--error);
    position: absolute;
    bottom: 0;
    left: 0;
}

/* ads */
.aaa {
    color: white;
    background-color: var(--mid-grey);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 600px;
    position: absolute;
    left: 1rem;
    top: 8rem;
}
.bbb {
    color: white;
    background-color: var(--mid-grey);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 250px;
    position: absolute;
    right: 1rem;
    top: 8rem;
}

/* tooltips for Equation.css and Variable.css */
.button-text {
    background-color: var(--dark-grey);
    color: white;
    font-family: 'Encode Sans';
    font-size: 1rem;
    text-align: center;
    padding: 0.3rem 0.5rem;
    border-radius: 0.2rem;
    position: absolute;
    top: 130%;
    visibility: hidden;
    z-index: 1;
}
.button-text::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -0.3rem;
    border-width: 0.3rem;
    border-style: solid;
    border-color: transparent transparent var(--dark-grey) transparent;
    z-index: 1;
}
.edit-button:hover + .button-text, .delete-button:hover + .button-text, .select-button:hover + .button-text {
    visibility: visible;
}
