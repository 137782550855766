@import url('https://fonts.googleapis.com/css2?family=Suez+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Encode+Sans:wght@300&display=swap');

:root {
    --accent: #3ca0e6;
    --error: #f44336;
    --light-grey: #202124;
    --mid-grey: #191a1c;
    --dark-grey: #101012;
}
html, #root, .App {
    height: 100%;
}
body {
    height: 100%;
    background-color: var(--light-grey);
}
a {
    text-decoration: none;
    color: var(--accent);
}

/* scrollbar */
::-webkit-scrollbar {
    width: 0.7rem;
}
::-webkit-scrollbar-thumb {
    background: linear-gradient(transparent, var(--accent) 20%, var(--accent) 80%, transparent);
}
::-webkit-scrollbar-thumb:hover {
    background-color: var(--accent);
}
