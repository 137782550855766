.Calculator-mobile {
    height: 1px;
    min-height: calc(100% - 5rem);
    overflow: auto;
}
.calculator-container-mobile {
    height: 1px;
    min-height: 100%;
    background-image: url(../../assets/repeating-background.png);
    background-repeat: no-repeat;
    background-position: top 3.5rem center;
    background-size: 98%;
}

/* pad section */
.pad-section-mobile {
    background-color: var(--mid-grey);
}
.pad-container-mobile {
    min-height: 3rem;
    margin: 0 1rem;
    display: flex;
    align-items: center;
}

/* main section */
@media screen and (orientation:portrait) {
    .main-section-mobile {
        min-height: calc(100% - 6.5rem);
        margin: 0 1rem;
        padding-bottom: 0.5rem;
    }
}
@media screen and (orientation:landscape) {
    .main-section-mobile {
        min-height: calc(100% - 6.5rem);
        margin: 0 1rem;
        padding-bottom: 0.5rem;
        display: flex;
        justify-content: space-between;
    }
}
.main-heading-mobile {
    margin: 0;
    padding: 1rem 0 0.5rem 0;
    color: white;
    font-family: 'Suez One';
    font-size: 1.25rem;
    text-align: center;
}
.main-heading-mobile-blank {
    margin: 0;
    padding: 1rem 0 0.5rem 0;
    color: dimgrey;
    font-family: 'Suez One';
    font-size: 1.25rem;
    text-align: center;
}
@media screen and (orientation:portrait) {
    .equation-container-mobile {
        width: 100%;
    }
    .variable-container-mobile {
        width: 100%;
    }
}
@media screen and (orientation:landscape) {
    .equation-container-mobile {
        width: 69%;
    }
    .variable-container-mobile {
        width: 29%;
    }
}

/* control section */
.control-section-mobile {
    background-color: var(--mid-grey);
}
.control-container-mobile {
    min-height: 3rem;
    margin: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media screen and (orientation:portrait) {
    .buttons-container-mobile {
        width: 20%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
@media screen and (orientation:landscape) {
    .buttons-container-mobile {
        width: 15%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
.bug-button-mobile, .help-button-mobile {
    background-color: transparent;
    color: white;
    border: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
}
.bug-button-mobile:hover {
    color: var(--error);
}
.help-button-mobile:hover {
    color: var(--accent);
}
.message-mobile {
    margin: 0;
    color: white;
    font-family: 'Encode Sans';
    font-size: 0.5rem;
    font-style: italic;
}
@media screen and (orientation:portrait) {
    .solve-button-mobile {
        background-color: transparent;
        color: white;
        border: 1px solid white;
        padding: 0;
        font-family: 'Suez One';
        font-size: 0.75rem;
        width: 20%;
        min-height: 1.5rem;
        position: relative;
        z-index: 1;
    }
}
@media screen and (orientation:landscape) {
    .solve-button-mobile {
        background-color: transparent;
        color: white;
        border: 1px solid white;
        padding: 0;
        font-family: 'Suez One';
        font-size: 0.75rem;
        width: 15%;
        min-height: 1.5rem;
        position: relative;
        z-index: 1;
    }
}
.solve-button-mobile:before {
    transition: 0.2s all ease;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 50%;
    opacity: 0;
    content: "";
    background-color: var(--accent);
}
.solve-button-mobile:hover:before {
    transition: 0.2s all ease;
    left: 0;
    right: 0;
    opacity: 1;
    z-index: -1;
}
