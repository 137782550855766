.Privacy {
    background-image: url(../../assets/repeating-background.png);
    background-repeat: no-repeat;
    background-position: top 1rem center;
    background-size: 65%;
}
.privacy-container {
    margin: 0 22rem;
    padding: 0.25rem 0 1.25rem 0;
    color: white;
}
