.Variable-mobile {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    padding: 0.75rem;
    min-height: 1.5rem;
    color: white;
    background-color: var(--mid-grey);
    position: relative;
}
.variable-text-mobile {
    margin: 0;
    font-family: 'Encode Sans';
    font-size: 1rem;
}
.variable-input-mobile {
    width: 96%;
    color: white;
    font-family: 'Encode Sans';
    font-size: 1rem;
    background-color: transparent;
    border: none;
    outline: none;
    border-bottom: 1px solid white;
}
.variable-input-mobile::placeholder {
    color: white;
}
.variable-input-mobile:focus {
    border-bottom: 1px solid var(--accent);
}
.variable-input-mobile:focus::placeholder {
    color: var(--accent);
}
.variable-value-mobile {
    width: 100%;
    font-family: 'Encode Sans';
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
}
.variable-grid-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.select-button-mobile {
    background-color: transparent;
    color: white;
    border: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    z-index: 2;
}
.select-button-mobile:hover {
    color: var(--accent);
}
