.equation-blank-item {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    padding: 1.2rem;
    height: 2.2rem;
    color: dimgrey;
    background: repeating-linear-gradient(135deg, var(--mid-grey), var(--mid-grey) 15px, transparent 15px, transparent 30px);
}
.equation-blank-text {
    margin: 0;
    font-family: 'Encode Sans';
    font-size: 1.5rem;
    font-style: italic;
}
.equation-blank-grid {
    display: flex;
    justify-content: center;
    align-items: center;
}
.blank-edit-button, .blank-delete-button {
    background-color: transparent;
    color: dimgrey;
    border: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
