.BugDialog {
    width: 54rem;
    background-color: var(--light-grey);
    padding: 3rem;
    display: flex;
    flex-direction: column;
}
.form-title {
    margin: 0;
    color: white;
    font-family: 'Suez One';
    font-size: 2rem;
}
.form-text {
    color: white;
    font-family: 'Encode Sans';
    font-size: 1.5rem;
    margin-top: 1.5rem;
    background-color: transparent;
    border: none;
    outline: none;
    border-bottom: 1px solid white;
}
.form-text::placeholder {
    color: white;
}
.form-text:focus {
    border-bottom: 1px solid var(--accent);
}
.form-text:focus::placeholder {
    color: var(--accent);
}
.form-textarea {
    resize: none;
    color: white;
    font-family: 'Encode Sans';
    font-size: 1.5rem;
    margin: 2rem 0;
    height: 10rem;
    background-color: transparent;
    border: none;
    outline: none;
    border: 1px solid white;
}
.form-textarea::placeholder {
    color: white;
}
.form-textarea:focus {
    border: 1px solid var(--accent);
}
.form-textarea:focus::placeholder {
    color: var(--accent);
}
.form-button {
    display: flex;
    flex-direction: row-reverse;
}
.form-submit {
    background-color: transparent;
    color: white;
    border: 1px solid white;
    padding: 0;
    font-family: 'Suez One';
    font-size: 1rem;
    cursor: pointer;
    width: 15%;
    height: 2rem;
    position: relative;
    z-index: 1;
}
.form-submit:before {
    transition: 0.2s all ease;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 50%;
    opacity: 0;
    content: "";
    background-color: var(--accent);
}
.form-submit:hover:before {
    transition: 0.2s all ease;
    left: 0;
    right: 0;
    opacity: 1;
    z-index: -1;
}
