.variable-blank-item-mobile {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    padding: 0.75rem;
    min-height: 1.5rem;
    color: dimgrey;
    background: repeating-linear-gradient(135deg, var(--mid-grey), var(--mid-grey) 15px, transparent 15px, transparent 30px);
}
.variable-blank-text-mobile {
    margin: 0;
    font-family: 'Encode Sans';
    font-size: 1rem;
    font-style: italic;
}
.variable-blank-grid-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
}
.blank-select-button-mobile {
    background-color: transparent;
    color: dimgrey;
    border: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.variable-blank-unselected-mobile {
    border-bottom: 1px solid dimgrey;
}
