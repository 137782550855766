.Footer {
    background-color: var(--dark-grey);
    display: flex;
    justify-content: center;
}
.footer-container {
    width: 20%;
    height: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.legal-nav {
    font-family: 'Encode Sans';
    font-size: 0.8rem;
    margin: 0;
    color: dimgrey;
}
.legal-nav:hover {
    color: var(--accent);
}
.copyright {
    font-family: 'Encode Sans';
    font-size: 0.8rem;
    margin: 0;
    color: dimgrey;
}
