/* hero */
@media screen and (orientation:portrait) {
    .hero-section-mobile {
        background: var(--mid-grey);
        background-image: url(../../assets/owl.png);
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 90% auto;
    }
}
@media screen and (orientation:landscape) {
    .hero-section-mobile {
        background: var(--mid-grey);
        background-image: url(../../assets/owl.png);
        background-repeat: no-repeat;
        background-position: left center;
        background-size: auto 85%;
    }
}
.hero-container-mobile {
    margin: 0 1rem;
    padding: 3rem 0;
    text-align: center;
}
.hero-header-mobile {
    margin: 0;
    font-family: 'Suez One';
    font-size: 3rem;
    color: white;
}
.hero-header-mobile span {
    color: var(--accent);
}
.hero-sub-mobile {
    margin: 0;
    font-family: 'Encode Sans';
    font-size: 1.25rem;
    color: white;
    line-height: 1.75rem;
}

/* features */
.features-section-mobile {
    background-image: url(../../assets/repeating-background.png);
    background-repeat: no-repeat;
    background-position: top 1.25rem center;
    background-size: 90% auto;
}
.features-container-mobile {
    margin: 0 1rem;
    padding: 2rem 0;
    text-align: center;
}
.features-header-mobile {
    margin: 0;
    font-family: 'Suez One';
    font-size: 1.5rem;
    color: white;
}
@media screen and (orientation:portrait) {
    .primary-cta-mobile {
        margin: 1rem 0;
        background: transparent;
        color: white;
        border: 1px solid white;
        padding: 0;
        font-family: 'Encode Sans';
        font-size: 1.25rem;
        width: 60%;
        min-height: 3rem;
        position: relative;
        z-index: 1;
    }
}
@media screen and (orientation:landscape) {
    .primary-cta-mobile {
        margin: 1rem 0;
        background: transparent;
        color: white;
        border: 1px solid white;
        padding: 0;
        font-family: 'Encode Sans';
        font-size: 1.25rem;
        width: 40%;
        min-height: 3rem;
        position: relative;
        z-index: 1;
    }
}
.primary-cta-mobile:before {
    transition: 0.2s all ease;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 50%;
    opacity: 0;
    content: "";
    background-color: var(--accent);
}
.primary-cta-mobile:hover:before {
    transition: 0.2s all ease;
    left: 0;
    right: 0;
    opacity: 1;
    z-index: -1;
}
.upcoming-features-header-mobile {
    margin: 0.5rem 0;
    font-family: 'Suez One';
    font-size: 1.25rem;
    color: white;
}
.upcoming-features-content-mobile {
    display: flex;
    flex-direction: column;
}
.upcoming-features-content-mobile p{
    margin: 0;
    font-family: 'Encode Sans';
    font-size: 1rem;
    color: white;
    line-height: 1.5rem;
}

/* about */
.about-section-mobile {
    background-color: var(--mid-grey);
}
@media screen and (orientation:portrait) {
    .about-container-mobile {
        margin: 0 1rem;
        padding: 2rem 0;
        display: flex;
        flex-direction: column;
    }
}
@media screen and (orientation:landscape) {
    .about-container-mobile {
        margin: 0 3rem;
        padding: 2rem 0;
        display: flex;
        flex-direction: column;
    }
}
.about-text-container-mobile {
    text-align: center;
}
.about-header-mobile {
    margin: 0;
    margin-bottom: 0.5rem;
    font-family: 'Suez One';
    font-size: 1.5rem;
    color: white;
}
.about-content-mobile {
    margin: 0;
    font-family: 'Encode Sans';
    font-size: 0.75rem;
    color: white;
    line-height: 1.25rem;
}
@media screen and (orientation:portrait) {
    .profile-container-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .profile-mobile {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
@media screen and (orientation:landscape) {
    .profile-container-mobile {
        display: flex;
        justify-content: space-around;
    }
    .profile-mobile {
        width: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
.profile-picture-mobile {
    width: 100%;
}
.profile-name-mobile {
    margin: 0.5rem 0;
    color: white;
    font-family: 'Encode Sans';
    font-size: 1.25rem;
    text-align: center;
}
.profile-description-mobile {
    margin: 0;
    color: white;
    font-family: 'Encode Sans';
    font-size: 1rem;
    text-align: center;
}
