.Terms-mobile {
    background-image: url(../../assets/repeating-background.png);
    background-repeat: no-repeat;
    background-position: top 1.25rem center;
    background-size: 90% auto;
}
.terms-container-mobile {
    margin: 0 1rem;
    padding: 0.25rem 0 1.25rem 0;
    color: white;
}
