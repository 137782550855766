.Variable {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    padding: 1.2rem;
    min-height: 2.2rem;
    color: white;
    background-color: var(--mid-grey);
    position: relative;
}
.variable-text {
    margin: 0;
    font-family: 'Encode Sans';
    font-size: 1.5rem;
}
.variable-input {
    width: 98%;
    color: white;
    font-family: 'Encode Sans';
    font-size: 1.5rem;
    background-color: transparent;
    border: none;
    outline: none;
    border-bottom: 1px solid white;
}
.variable-input::placeholder {
    color: white;
}
.variable-input:focus {
    border-bottom: 1px solid var(--accent);
}
.variable-input:focus::placeholder {
    color: var(--accent);
}
.variable-value {
    width: 100%;
    font-family: 'Encode Sans';
    font-size: 1.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    /* white-space: nowrap; */
}
.variable-grid {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.select-button {
    background-color: transparent;
    color: white;
    border: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    cursor: pointer;
    z-index: 2;
}
.select-button:hover {
    color: var(--accent);
}

/* tooltips in Calculator.css */
