.Equation {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    padding: 1.2rem;
    min-height: 2.2rem;
    color: white;
    background-color: var(--mid-grey);
    position: relative;
}
.equation-text {
    margin: 0;
    font-family: 'Encode Sans';
    font-size: 1.5rem;
    word-wrap: break-word;
}
.equation-grid {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.edit-button, .delete-button {
    background-color: transparent;
    color: white;
    border: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    cursor: pointer;
    z-index: 2;
}
.edit-button:hover {
    color: var(--accent);
}
.delete-button:hover {
    color: var(--error);
}

/* tooltips in Calculator.css */
