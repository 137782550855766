.Nav {
    background-color: var(--dark-grey);
}
.nav-container {
    margin: 0 22rem;
    height: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.home-nav {
    margin: 0;
    color: white;
    font-family: 'Suez One';
    font-size: 1.5rem;
}
.home-nav span {
    color: var(--accent);
}
.home-nav:hover {
    color: var(--accent);
}
.calculator-nav {
    margin: 0;
    color: white;
    font-family: 'Encode Sans';
    font-size: 1.25rem;
}
.calculator-nav:hover {
    color: var(--accent);
}
